import React from "react";
import {PropagateLoader} from "react-spinners";

// Countries names internationalized
export const countryNameInLang = require("i18n-iso-countries");
countryNameInLang.registerLocale(require("i18n-iso-countries/langs/en.json"));
countryNameInLang.registerLocale(require("i18n-iso-countries/langs/fr.json"));

export const PARENT_TYPE_OPTIONS = [
  "FATHER",
  "MOTHER",
  "REPRESENTATIVE",
  "OTHER"
];

export const FLUX_OPTIONS = [
  {value:"ALL",for:"payment"},
  {value:"INCOMING",for:"year,student,payment"},
  {value:"OUTGOING",for:"year,payment"},
  {value:"DISCOUNT",for:"student,payment"}
];

export const FINANCIAL_REASON_OPTIONS = [
  "REGISTRATION",
  "TUITION",
  "MEALS",
  "BUS",
  "UNIFORM"
];

export const FINANCIAL_REASON_OPTIONS_2 = [
  "ALL",
  "REGISTRATION",
  "TUITION",
  "MEALS",
  "BUS",
  "UNIFORM"
];

export const RECOVERY_TYPE = [
  "ALL",
  "OK",
  "NOT_OK",
];

export const RECOVERY_OBJECT = [
  "ALL",
  "REGISTRATION",
  "TUITION",
  "MEALS",
  "BUS"
];

export const REQUEST_STATUS = [
  "ALL",
  "PENDING",
  "ACCEPTED",
  "REJECTED",
];

export const SOURCE_OPTIONS_FILTER = [
  "ALL",
  "CASH",
  "CHEQUE",
  "BANK_TRANSFER",
];

export const SOURCE_OPTIONS = [
  "CASH",
  "CHEQUE",
  "BANK_TRANSFER",
];

export const GENDER_OPTIONS = [
  "MALE",
  "FEMALE",
  "OTHER",
];

export const TEACHER_STATUS_OPTIONS = [
  "PART_TIME",
  "PERMANENT",
];

export const BOOL_OPTIONS = [
  "yes",
  "no",
];

export const CATEGORY_OPTIONS = [
  "ALL",
  "YEARS",
  "STUDENTS",
];

export const STAFF_MEMBER_TYPE_OPTIONS = [
  "ADMINISTRATIVE",
  "TEACHER",
  "OTHER",
];

export const EVAL_STATUS_OPTIONS = [
  "ONGOING",
  "FINISHED",
  "ABORTED",
];

// Colors
export const platform_green_color = '#5E923D';
export const platform_red_color = '#e55353';
export const platform_blue_color = '#321fdb';

// Remote call limit page
export const remote_call_limit = 50;

// Spinner use to wait the mount of components
export const firstSpinner = (height,size) =>
  <>
    <div className="d-flex justify-content-center align-items-center" style={{height:height != null ? height : "50vh"}}>
      <PropagateLoader
        sizeUnit={"px"}
        size={size != null ? size : 10}
        color={platform_green_color}
      />
    </div>
  </>;

// Strong password regex
export const strongPassword = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@*?#_\-=+]).{4,}$/);

// Languages constants
export const _app_name = 'app_name';
export const _school_name = 'school_name';
export const _app_logo = 'app_logo';
export const _log_in = 'log_in';
export const _log_in_again = 'log_in_again';
export const _my_account = 'my_account';
export const _logout = 'logout';
export const _error_message_default_2 = 'error_message_default_2';
export const _sign_in_to_your_account = 'sign_in_to_your_account';
export const _password = 'password';
export const _welcome_message_with_user_name = 'welcome_message_with_user_name';
export const _welcome_message_without_user_name = 'welcome_message_without_user_name';
export const _goodbye = 'goodbye';
export const _loading = 'loading';
export const _of = 'of';
export const _english = 'english';
export const _french = 'french';
export const _language = 'language';
export const _we_have_a_problem = 'we_have_a_problem';
export const _back_to_home_page = 'back_to_home_page';
export const _you_are_lost = 'you_are_lost';
export const _page_not_found = 'page_not_found';
export const _enter_some_text = 'enter_some_text';
export const _missing_text_to_search = 'missing_text_to_search';
export const _home = 'home';
export const _phone_number = 'phone_number';
export const _actions = 'actions';
export const _email_address = 'email_address';
export const _name = 'name';
export const _created_by = 'created_by';
export const _status = 'status';
export const _type = 'type';
export const _created_at = 'created_at';
export const _updated_at = 'updated_at';
export const _details = 'details';
export const _welcome = 'welcome';
export const _page = 'page';
export const _loading_pdf_text = 'loading_pdf_text';
export const _not_connected_message = 'not_connected_message';
export const _login_welcome_text = 'login_welcome_text';
export const _platform_management = 'platform_management';
export const _platform_management_2 = 'platform_management_2';
export const _platform = 'platform';
export const _users = 'users';
export const _manage = 'manage';
export const _operation_performed = 'operation_performed';
export const _full_names = 'full_names';
export const _no_user_created_yet = 'no_user_created_yet';
export const _create_an_user = 'create_an_user';
export const _active = 'active';
export const _inactive = 'inactive';
export const _first_name = 'first_name';
export const _last_name = 'last_name';
export const _role = 'role';
export const _roles = 'roles';
export const _back = 'back';
export const _create = 'create';
export const _email_address_not_valid = 'email_address_not_valid';
export const _yes = 'yes';
export const _no = 'no';
export const _information = 'information';
export const _delete = 'delete';
export const _edition = 'edition';
export const _edit = 'edit';
export const _make = 'make';
export const _reset_password = 'reset_password';
export const _change_password = 'change_password';
export const _default_generated_password_message = 'default_generated_password_message';
export const _actual_password = 'actual_password';
export const _new_password = 'new_password';
export const _confirm_password = 'confirm_password';
export const _must_match_new_password = 'must_match_new_password';
export const _password_constraint_text = 'password_constraint_text';
export const _modify = 'modify';
export const _ok = 'ok';
export const _administration = 'administration';
export const _school_years = 'school_years';
export const _students = 'students';
export const _create_a_school_year = 'create_a_school_year';
export const _no_school_year_created_yet = 'no_school_year_created_yet';
export const _delete_an_element_question = 'delete_an_element_question';
export const _year_selected = 'year_selected';
export const _school_year = 'school_year';
export const _no_classes_created_yet = 'no_classes_created_yet';
export const _add_a_class = 'add_a_class';
export const _registration_fees = 'registration_fees';
export const _tuition_fees = 'tuition_fees';
export const _meals_fees = 'meals_fees';
export const _classSelected = 'classSelected';
export const _remove_student_from_class = 'remove_student_from_class';
export const _remove = 'remove';
export const _class = 'class';
export const _add_a_student = 'add_a_student';
export const _no_student_in_class_now = 'no_student_in_class_now';
export const _sex = 'sex';
export const _living_place = 'living_place';
export const _birth_date = 'birth_date';
export const _birth_place = 'birth_place';
export const _nationality = 'nationality';
export const _last_class = 'last_class';
export const _last_school = 'last_school';
export const _complete = 'complete';
export const _complete_required_fields = 'complete_required_fields';
export const _additional_information = 'additional_information';
export const _find_student = 'find_student';
export const _no_student_found = 'no_student_found';
export const _student_selected = 'student_selected';
export const _cancel = 'cancel';
export const _write_x = 'write_x';
export const _registration_number = 'registration_number';
export const _general_information = 'general_information';
export const _student_parents = 'student_parents';
export const _add_a_note = 'add_a_note';
export const _add_student_to_a_class = 'add_student_to_a_class';
export const _no_parent_added_yet = 'no_parent_added_yet';
export const _continue = 'continue';
export const _occupation = 'occupation';
export const _add = 'add';
export const _phone_number_not_valid = 'phone_number_not_valid';
export const _emergency_contact = 'emergency_contact';
export const _parent_information = 'parent_information';
export const _remove_parent_question = 'remove_parent_question';
export const _payments = 'payments';
export const _classes = 'classes';
export const _join_meals_question = 'join_meals_question';
export const _join_meals_question_2 = 'join_meals_question_2';
export const _join_bus_question = 'join_bus_question';
export const _join_bus_question_2 = 'join_bus_question_2';
export const _parents = 'parents';
export const _add_parent = 'add_parent';
export const _parent_selected = 'parent_selected';
export const _note = 'note';
export const _no_text_in_note = 'no_text_in_note';
export const _files = 'files';
export const _add_file = 'add_file';
export const _no_file_yet_students = 'no_file_yet_students';
export const _no_file_yet_staffs = 'no_file_yet_staffs';
export const _choose_file = 'choose_file';
export const _size = 'size';
export const _student_has_no_class_yet = 'student_has_no_class_yet';
export const _add_student_in_a_class = 'add_student_in_a_class';
export const _no_payment_yet = 'no_payment_yet';
export const _add_payment = 'add_payment';
export const _payment_selected = 'payment_selected';
export const _reference = 'reference';
export const _amount = 'amount';
export const _date = 'date';
export const _flux = 'flux';
export const _effective = 'effective';
export const _reason = 'reason';
export const _class_information = 'class_information';
export const _academic_record = 'academic_record';
export const _financial_record = 'financial_record';
export const _years_and_classes = 'years_and_classes';
export const _edit_note = 'edit_note';
export const _recovery = 'recovery';
export const _nothing_to_display_yet = 'nothing_to_display_yet';
export const _object = 'object';
export const _registration = 'registration';
export const _tuition = 'tuition';
export const _meals = 'meals';
export const _bus = 'bus';
export const _progression = 'progression';
export const _select_class = 'select_class';
export const _class_selected = 'class_selected';
export const _school_management = 'school_management';
export const _reports = 'reports';
export const _source = 'source';
export const _bus_fees = 'bus_fees';
export const _create_report = 'create_report';
export const _no_report_yet = 'no_report_yet';
export const _content = 'content';
export const _no_text_in_this_report = 'no_text_in_this_report';
export const _report_selected = 'report_selected';
export const _total = 'total';
export const _paid = 'paid';
export const _discount = 'discount';
export const _left_to_pay = 'left_to_pay';
export const _select = 'select';
export const _school_administration = 'school_administration';
export const _finance = 'finance';
export const _academy = 'academy';
export const _list_of_year_students = 'list_of_year_students';
export const _list_of_class_students = 'list_of_class_students';
export const _list_of_class_payments = 'list_of_class_payments';
export const _list_of_student_payments_for_year = 'list_of_student_payments_for_year';
export const _list_of_student_payments_for_class = 'list_of_student_payments_for_class';
export const _list_of_internal_payments_for_year = 'list_of_internal_payments_for_year';
export const _add_profile_photo = 'add_profile_photo';
export const _profile_photo = 'profile_photo';
export const _profile_photo_selected = 'profile_photo_selected';
export const _close = 'close';
export const _category = 'category';
export const _student = 'student';
export const _incoming = 'incoming';
export const _outgoing = 'outgoing';
export const _registration_due = 'registration_due';
export const _tuition_due = 'tuition_due';
export const _meals_due = 'meals_due';
export const _bus_due = 'bus_due';
export const _financial_record_value_unknown = 'financial_record_value_unknown';
export const _picture_not_found = 'picture_not_found';
export const _finances = 'finances';
export const _subjects = 'subjects';
export const _staff_management = 'staff_management';
export const _staff_list = 'staff_list';
export const _no_element_found = 'no_element_found';
export const _add_a_staff_member = 'add_a_staff_member';
export const _create_a_staff_member = 'create_a_staff_member';
export const _position = 'position';
export const _normal = 'normal';
export const _via_existing_user = 'via_existing_user';
export const _search_a_user = 'search_a_user';
export const _selected_user = 'selected_user';
export const _created_from_user = 'created_from_user';
export const _add_a_subject = 'add_a_subject';
export const _multiplier = 'multiplier';
export const _teacher = 'teacher';
export const _subject_selected = 'subject_selected';
export const _select_a_teacher = 'select_a_teacher';
export const _teacher_selected = 'teacher_selected';
export const _main_teacher = 'main_teacher';
export const _evaluations = 'evaluations';
export const _evaluation = 'evaluation';
export const _search_a_staff_member = 'search_a_staff_member';
export const _add_an_evaluation = 'add_an_evaluation';
export const _evaluation_selected = 'evaluation_selected';
export const _multiplier_2 = 'multiplier_2';
export const _select_at_least_on_subject = 'select_at_least_on_subject';
export const _select_one_or_more_subjects = 'select_one_or_more_subjects';
export const _subjects_selected = 'subjects_selected';
export const _select_a_subject = 'select_a_subject';
export const _status_edition = 'status_edition';
export const _select_new_status = 'select_new_status';
export const _edit_general_information = 'edit_general_information';
export const _edit_status = 'edit_status';
export const _filled = 'filled';
export const _not_filled = 'not_filled';
export const _save = 'save';
export const _calculate_eval_results = 'calculate_eval_results';
export const _choose_subjects_to_exclude = 'choose_subjects_to_exclude';
export const _launch_calculation = 'launch_calculation';
export const _evaluation_results = 'evaluation_results';
export const _average = 'average';
export const _absences = 'absences';
export const _absence_list = 'absence_list';
export const _add_an_absence = 'add_an_absence';
export const _absence_selected = 'absence_selected';
export const _start_time = 'start_time';
export const _end_time = 'end_time';
export const _add_a_supporting_file = 'add_a_supporting_file';
export const _supporting_file = 'supporting_file';
export const _staff = 'staff';
export const _supporting_document_question = 'supporting_document_question';
export const _unknown = 'unknown';
export const _timetable = 'timetable';
export const _add_a_time_slot = 'add_a_time_slot';
export const _selected_time_slot = 'selected_time_slot';
export const _day = 'day';
export const _choose_a_day = 'choose_a_day';
export const _hour = 'hour';
export const _subject = 'subject';
export const _dispensed_by = 'dispensed_by';
export const _can_teach = 'can_teach';
export const _can_teach_2 = 'can_teach_2';
export const _teacher_status = 'teacher_status';
export const _delete_student_from_class_warning_1 = 'delete_student_from_class_warning_1';
export const _delete_student_from_class_warning_2 = 'delete_student_from_class_warning_2';
export const _delete_student_from_class_warning_3 = 'delete_student_from_class_warning_3';
export const _delete_student_from_class_warning_4 = 'delete_student_from_class_warning_4';
export const _total_expected = 'total_expected';
export const _total_reductions = 'total_reductions';
export const _actual_expected_total = 'actual_expected_total';
export const _class_groups = 'class_groups';
export const _select_minimum_one_class = 'select_minimum_one_class';
export const _create_a_class_group = 'create_a_class_group';
export const _class_group_selected = 'class_group_selected';
export const _select_classes = 'select_classes';
export const _selected_classes = 'selected_classes';
export const _no_class_selected_yet = 'no_class_selected_yet';
export const _transfer = 'transfer';
export const _transfer_student = 'transfer_student';
export const _transfer_class = 'transfer_class';
export const _year = 'year';
export const _class_group = 'class_group';
export const _current_class = 'current_class';
export const _special = 'special';
export const _tuition_due_dates = 'tuition_due_dates';
export const _meals_due_dates = 'meals_due_dates';
export const _bus_due_dates = 'bus_due_dates';
export const _bus_zones = 'bus_zones';
export const _no_deadline_set_yet = 'no_deadline_set_yet';
export const _add_deadline = 'add_deadline';
export const _fees = 'fees';
export const _due_dates = 'due_dates';
export const _not_paid = 'not_paid';
export const _not_defined = 'not_defined';
export const _add_bus_zone = 'add_bus_zone';
export const _bus_zone = 'bus_zone';
export const _description = 'description';
export const _rank = 'rank';
export const _exit = 'exit';
export const _results_recorded = 'results_recorded';
export const _see_storage_results = 'see_storage_results';
export const _select_an_evaluation = 'select_an_evaluation';
export const _select_one_or_more_evaluations = 'select_one_or_more_evaluations';
export const _coef = 'coef';
export const _select_at_least_one_evaluation = 'select_at_least_one_evaluation';
export const _evaluations_selected = 'evaluations_selected';
export const _subjects_or_evaluations = 'subjects_or_evaluations';
export const _no_role_assigned = 'no_role_assigned';
export const _you_re_not_the_teacher_of_this_subject = 'you_re_not_the_teacher_of_this_subject';
export const _registration_requests = 'registration_requests';
export const _no_registration_requests_yet = 'no_registration_requests_yet';
export const _system_information = 'system_information';
export const _father_name = 'father_name';
export const _father_occupation = 'father_occupation';
export const _father_mobile = 'father_mobile';
export const _father_email = 'father_email';
export const _mother_name = 'mother_name';
export const _mother_occupation = 'mother_occupation';
export const _mother_mobile = 'mother_mobile';
export const _mother_email = 'mother_email';
export const _contacting_father_in_an_emergency = 'contacting_father_in_an_emergency';
export const _contacting_mother_in_an_emergency = 'contacting_mother_in_an_emergency';
export const _comment = 'comment';
export const _education = 'education';
export const _class_to_enroll_student = 'class_to_enroll_student';
export const _last_schools_attended = 'last_schools_attended';
export const _medical_information = 'medical_information';
export const _diseases_question = 'diseases_question';
export const _medication_question = 'medication_question';
export const _doctor_name = 'doctor_name';
export const _doctor_mobile = 'doctor_mobile';
export const _learning_issues_question = 'learning_issues_question';
export const _mobility_issues_question = 'mobility_issues_question';
export const _registration_request = 'registration_request';
export const _reject = 'REJECT';
export const _accept = 'ACCEPT';
export const _accept_registration_request_question = 'accept_registration_request_question';
export const _reject_registration_request_question = 'reject_registration_request_question';
export const _modification_applied = 'modification_applied';
export const _tutor_name = 'tutor_name';
export const _tutor_occupation = 'tutor_occupation';
export const _tutor_mobile = 'tutor_mobile';
export const _tutor_email = 'tutor_email';
export const _contacting_tutor_in_an_emergency = 'contacting_tutor_in_an_emergency';
export const _school = 'school';
export const _download = 'download';
export const _select_an_item = 'select_an_item';
export const _comment_on_registration_form = 'comment_on_registration_form';
export const _manage_student = 'manage_student';
export const _download_receipt_question = 'download_receipt_question';
export const _list_of_registration_forms = 'list_of_registration_forms';
export const _warning_student_registration_number_not_present = 'warning_student_registration_number_not_present';
export const _select_category = 'select_category';
export const _launch_reporting = 'launch_reporting';
export const _reporting_in_progress = 'reporting_in_progress';
export const _download_report = 'download_report';
export const _exam = 'exam';
export const _homework = 'homework';
export const _select_type_for_eval = 'select_type_for_eval';
export const _ = '';


// Platform roles
export const ROLES_OPTIONS = [
  {name:_platform_management_2, actions:"READ_MANAGEMENT,WRITE_MANAGEMENT"},
  {name:_school_administration, actions:"READ_ADMINISTRATION,WRITE_ADMINISTRATION"},
  {name:_staff_management, actions:"READ_STAFF,WRITE_STAFF"},
  {name:_finance, actions:"READ_FINANCES,WRITE_FINANCES,READ_YEAR_FINANCES,WRITE_YEAR_FINANCES"},
  {name:_academy, actions:"READ_ACADEMIC,WRITE_ACADEMIC"},
  {name:_reports, actions:"REPORTER,REPORT_ADMIN"},
  {name:_special, actions:"WRITE_ADMINISTRATION_EDIT_STUDENT,READ_FINANCES_STUDENT,WRITE_FINANCES_CREATE_STUDENT_PAYMENTS"},
];
